/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Blank.vue?vue&type=template&id=45d69b6c&"
var script = {}
import style0 from "./Blank.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports